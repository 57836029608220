import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {debounceTime, delay, map, switchMap, tap} from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({providedIn: 'root'})
export class AuthService {
    public token;
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(private httpClient: HttpClient, private router: Router){
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    reset(email, rol) {
        return this.httpClient.post(`/accounts/reset`, {email: email, rol: rol});
    }

    login(email, password, rol) {
        return this.httpClient.post(`/login`, {email: email, password: password, rol: rol}).pipe(map(user => {
            if(user && user['token']) {
                this.currentUserSubject.next(null);
                localStorage.setItem('currentUser', JSON.stringify(user));
                localStorage.setItem('token', user['token']);
                this.currentUserSubject.next(user);
            }
            return user;
        }));
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('currentUser');
        this.router.navigate(['/auth/login']);
    }
}