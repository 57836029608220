import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment.prod';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: environment.footer,
})
export class FooterComponent {
}
